<template>
     <div
          class="card card-compact bg-base-200 bg-opacity-60 mt-5 cursor-pointer"
          v-for="(topic, index) in main_topics"
          v-bind:onclick="'S' + index + '.showModal()'"
        >
          <div class="card-body">
            <span class="text-lg font-bold text-accent-content">{{
              topic.topic
            }}</span>
            <span> {{ topic.detailed_topic_summary.slice(0, 90) }}... 
              <i>read more</i>
             </span>
            
    
            
          </div>

          <dialog v-bind:id="'S' + index" class="modal">
            <form method="dialog" class="modal-box w-11/12">
              <button
                class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              >
                ✕
              </button>
              <h3 class="font-bold text-lg">{{ topic.topic }}</h3>
              <div 
                class="py-4 modal_description"
                v-html="markdown.render(topic.detailed_topic_summary)"
              ></div>
              <hr>
              <div class="searchable mt-3 text-sm">
                You can search for this topic in the chat using the below keywords (tap/click to copy): <br>
              <span @click="c2c(term)"
                class="badge badge-accent mx-1 py-1 text-md mt-3 cursor-pointer"
                v-for="term in splitter(topic.search_terms)"
                >{{ term }}
                <i ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="copy_svg w-4 h-4 ml-2 cursor-pointer">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
</svg>
</i>
                </span
              >
            </div>
            </form>
            <form method="dialog" class="modal-backdrop">
    <button>close</button>
  </form>
          </dialog>
        </div>
</template>

<script setup>
 import MarkdownIt from "markdown-it";
import { copyText } from 'vue3-clipboard'

 const props = defineProps({
    main_topics : Array

 })

 function splitter(arrayorstring) {
  //console.log(" Slicer output: " + arrayorstring)
  if (Array.isArray(arrayorstring)) {
    return arrayorstring;
  } else {
    return arrayorstring.split(",");
  }
}

function c2c(text2copy){
  copyText(text2copy, undefined, (error, event) => {
          if (error) {
            alert('Can not copy')
            console.log(error)
          } else {
            console.log(event)
          }
        })
}

const markdown = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true
});
</script>

<style lang="scss" scoped>

</style>