import { default as indexzkERW0S0RTMeta } from "/vercel/path0/frontend/pages/index.vue?macro=true";
import { default as leaderboardmn2HH76OA4Meta } from "/vercel/path0/frontend/pages/leaderboard.vue?macro=true";
import { default as _91date_93BDDnmcLJkQMeta } from "/vercel/path0/frontend/pages/summary/[date].vue?macro=true";
export default [
  {
    name: indexzkERW0S0RTMeta?.name ?? "index",
    path: indexzkERW0S0RTMeta?.path ?? "/",
    meta: indexzkERW0S0RTMeta || {},
    alias: indexzkERW0S0RTMeta?.alias || [],
    redirect: indexzkERW0S0RTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardmn2HH76OA4Meta?.name ?? "leaderboard",
    path: leaderboardmn2HH76OA4Meta?.path ?? "/leaderboard",
    meta: leaderboardmn2HH76OA4Meta || {},
    alias: leaderboardmn2HH76OA4Meta?.alias || [],
    redirect: leaderboardmn2HH76OA4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/pages/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: _91date_93BDDnmcLJkQMeta?.name ?? "summary-date",
    path: _91date_93BDDnmcLJkQMeta?.path ?? "/summary/:date()",
    meta: _91date_93BDDnmcLJkQMeta || {},
    alias: _91date_93BDDnmcLJkQMeta?.alias || [],
    redirect: _91date_93BDDnmcLJkQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/pages/summary/[date].vue").then(m => m.default || m)
  }
]