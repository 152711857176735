import revive_payload_client_4sVQNw7RlN from "/vercel/path0/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/vercel/path0/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/vercel/path0/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes
]